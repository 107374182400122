import format from 'date-fns/format';
import validators from '@advisa/common-validators';
import stripDelimiter from '../../utils/strip-delimiter-from-currency';
import { normalizePhoneNumber } from '../../utils/normalize-phone-number';
import {
  isEmployed,
  isTempEmployed,
  hasSpouse,
} from './applicantFunctions';
import mappers from '../../enums/index';
import enums from '../../enums/shared';
import { loanTypeMapper } from './currentLoans';

const DEFAULT_REPAYMENT_TIME = 360;

const normalizeEmail = (email) => {
  const result = validators.email.parse(email);
  return result.formatted;
};

const formatDate = (year, month) => format(new Date(year, month || 0), 'yyyy-MM-dd\'T\'HH:mm:ss');

const formatInt = (number) => {
  const formatted = parseInt(number, 10);
  return Number.isNaN(formatted) ? null : formatted;
};

const formatCurrency = (number) => formatInt(stripDelimiter(number));

const mapCurrentLoans = (data = {}) => Object.entries(data)
  .filter(([key]) => key !== 'numCurrentLoans')
  .map(([key, value]) => ({
    type: (key === 'houseLoan' || key === 'studentLoan') ? mappers.currentLoanType[loanTypeMapper(key)] : mappers.currentLoanType[key],
    amount: formatCurrency(value.amount),
    monthlyPayment: formatCurrency(value.monthlyCost),
    transfer: key === 'houseLoan',
  }));

const createCarsArray = (ownedTotalAmount) => Array.from({ length: Number(ownedTotalAmount) },
  () => ({ monthlyCost: 0, leased: false }));

const formatMoveInDate = (moveInDate) => (moveInDate ? format(new Date(moveInDate, 0), 'yyyy-MM-dd\'T\'HH:mm:ss') : null);

const mapApplicant = (applicant, cars = {}, currentLoans = []) => {
  if (!applicant) {
    return null;
  }

  const output = {
    email: normalizeEmail(applicant.email),
    normalizedEmail: normalizeEmail(applicant.email),
    mobilePhone: normalizePhoneNumber(applicant.phoneNumber),
    nationalId: applicant.nationalId || null,
    civilStatus: mappers.civilStatus[applicant.civilStatus],
    employmentType: mappers.employmentType[applicant.employmentType],
    employerName: (isEmployed(applicant) && applicant.employerName) || null,
    employmentSince: applicant.employmentSince
      ? formatDate(applicant.employmentSince, applicant.employmentSinceMonth)
      : null,
    employmentTo: isTempEmployed(applicant) && applicant.employmentTo
      ? formatDate(applicant.employmentTo, applicant.employmentToMonth)
      : null,
    monthlyIncomeBeforeTax: Math.round(formatCurrency(applicant.annualIncomeBeforeTax) / 12),
    numDependants: applicant.dependants,
    homeCost: formatCurrency(applicant.homeCost),
    homeType: mappers.homeType[applicant.homeType],
    currentLoans,
    monthlyIncomeAfterTax: formatCurrency(applicant.monthlyIncomeAfterTax),
    educationLevel: mappers.educationLevel[applicant.educationLevel],
    cars: createCarsArray(cars.ownedTotalAmount),
    moveInDate: formatMoveInDate(applicant.moveInDate),
    otherMonthlyIncomeAfterTax: formatCurrency(applicant.otherMonthlyIncomeAfterTax) || null,
    rentalMonthlyIncomeBeforeTax: formatCurrency(applicant.rentalMonthlyIncomeBeforeTax) || null,
    spouseMonthlyIncomeBeforeTax: hasSpouse(applicant) && applicant.spouseAnnualIncomeBeforeTax
      ? Math.round(parseInt(stripDelimiter(applicant.spouseAnnualIncomeBeforeTax), 10) / 12) : null,
  };

  return output;
};

export const mapApplication = (application) => {
  const {
    acceptNewsletter,
    applicant,
    coApplicant,
    cars,
  } = application;

  const currentLoans = mapCurrentLoans(applicant.currentLoans);
  const mortgage = currentLoans.find(({ type }) => type === enums.currentLoanType.MORTGAGE);

  const data = {
    purpose: enums.purpose.COMBINING_LOANS,
    acceptNewsletter: !!acceptNewsletter,
    applicant: mapApplicant(applicant, cars, currentLoans),
    secondaryApplicant: mapApplicant(coApplicant),
    inputSource: application.source,
    totalLoan: mortgage?.amount ?? 0,
    desiredRepaymentTime: DEFAULT_REPAYMENT_TIME,
    applicationType: 'MORTGAGE',
  };

  return data;
};

export default { mapApplication };
